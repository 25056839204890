import { INotice, Notifier } from "@airbrake/browser"
// need to specifically get the UMD version
import Browser from "browser-detect"

import { ENVIRONMENT, IS_DEVELOPMENT, IS_TEST } from "./environment"

// from config/initializers/airbrake.rb
const PROJECT_ID = 37_617
const PROJECT_KEY = "f5eaf963bfcfe86e41acb8184bc4bea5"

const minimumVersions: Record<string, number> = {
  android: 120,
  chrome: 109,
  edge: 119,
  firefox: 115,
  ie: 12, // There is no IE 12 of course, this is just to ignore all IEs
  ios: 15.6,
  safari: 16.6
}

const ignoredErrors = [
  /^document.getElementsByClassName.ToString is not a function/,
  /^Blocked a frame with origin/,
  /^Calculating structure twice ! Should not happen/,
  // https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062/29
  /^Object Not Found Matching Id/,
  /^(cancelled|annulé|cancelado)$/, // Safari throws this on aborted fetch request
  /^undefined is not an object \(evaluating '__gCrWeb.edgeTranslate.detectPageState'\)$/ // Ignore GCR errors
]

const ignoredFilePrefixes = [
  "chrome-extension://",
  "file:///Applications/Honey.app",
  "HoneyError",
  "https://config1.veinteractive.com",
  "https://home-l32.niceincontact.com/inContact/ChatClient/js/embed.min.js",
  "safari-extension://",
  "safari-web-extension://",
  "webkit-masked-url://hidden"
]

const browser = Browser()
let minimumVersionNumber: number
if (browser.name) {
  minimumVersionNumber = minimumVersions[browser.name] || 0
} else {
  minimumVersionNumber = 0
}

const ignoreError = (notice: INotice): boolean => {
  if (!notice.errors) {
    return true
  }

  for (const ignored of ignoredErrors) {
    if (ignored.test(notice.errors[0].message)) {
      return true
    }
  }

  return false
}

const isUsingSupportedBrowser = (): boolean => {
  if (!browser.versionNumber) {
    return false
  }

  if (browser.mobile && browser.name === "opera") {
    return false
  }

  return browser.versionNumber >= minimumVersionNumber
}

const ignoreFile = (notice: INotice): boolean => {
  for (const ignored of ignoredFilePrefixes) {
    if (!notice.errors) {
      return true
    }

    for (const line of notice.errors[0].backtrace) {
      if (line.file.indexOf(ignored) === 0) {
        return true
      }
    }
  }
  return false
}

const airbrakeClient = new Notifier({
  environment: ENVIRONMENT || "production",
  projectId: PROJECT_ID,
  projectKey: PROJECT_KEY,
  remoteConfig: false
})

airbrakeClient.addFilter((notice: INotice): INotice | null => {
  // Do not report blog errors, we don't care about them.
  if (window.location.pathname.startsWith("/blog/")) {
    return null
  }
  if (ignoreError(notice)) {
    return null
  }
  if (!isUsingSupportedBrowser()) {
    return null
  }
  if (ignoreFile(notice)) {
    return null
  }

  notice.context.os = navigator.platform

  if (IS_DEVELOPMENT || IS_TEST) {
    console.log(notice) // eslint-disable-line no-console
    return null
  } else {
    return notice
  }
})

export const logDecodingError = (flags: Flags): void => {
  void airbrakeClient.notify({
    error: `Error decoding flags or payload at ${window.location.href}`,
    params: flags
  })
}

export default airbrakeClient
