enum ENV {
  DEVELOPMENT = "development",
  PRODUCTION = "production",
  STAGING = "staging",
  TEST = "test"
}

export const ENVIRONMENT: ENV | null | undefined = <ENV>(
  document.querySelector("meta[name='server-environment']")?.getAttribute("content")
)
export const IS_DEVELOPMENT = ENVIRONMENT === ENV.DEVELOPMENT
export const IS_PRODUCTION = ENVIRONMENT === ENV.PRODUCTION
export const IS_TEST = ENVIRONMENT === ENV.TEST
export const IS_PRODUCTION_OR_STAGING = IS_PRODUCTION || ENVIRONMENT === ENV.STAGING
