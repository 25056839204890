import Cookies from "js-cookie"

import { IS_PRODUCTION_OR_STAGING } from "./environment"

const attributes = {
  samesite: "lax",
  secure: false
}

if (IS_PRODUCTION_OR_STAGING) {
  attributes.secure = true
}

export default Cookies.withAttributes(attributes)
