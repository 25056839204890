const NATIVE_SCROLL_BEHAVIOR = "scrollBehavior" in document.documentElement.style

// Robert Penner's quadratic in/out easing function
// http://robertpenner.com/easing/penner_chapter7_tweening.pdf#page=22
const easeInOutQuad = (time: number, begin: number, change: number, duration: number): number => {
  time /= duration / 2

  if (time < 1) {
    return (change / 2) * time * time + begin
  } else {
    time--
    return (-change / 2) * (time * (time - 2) - 1) + begin
  }
}

const scrollLeft = (target: HTMLElement, duration: number): void => {
  const container = target.parentElement
  if (!container) return

  const to = target.offsetLeft
  const start = container.scrollLeft
  const change = to - start
  const startDate = +new Date()

  const smoothScroll = (): void => {
    const currentDate = +new Date()
    const currentTime = currentDate - startDate
    container.scrollLeft = easeInOutQuad(currentTime, start, change, duration)

    if (currentTime < duration) {
      requestAnimationFrame(smoothScroll)
    } else {
      container.scrollLeft = to
    }
  }

  smoothScroll()
}

export const scrollLeftIntoView = (target: HTMLElement): void => {
  if (!target) return

  if (NATIVE_SCROLL_BEHAVIOR) {
    target.parentElement?.scrollTo({
      left: target.offsetLeft,
      behavior: "smooth"
    })
  } else {
    scrollLeft(target, 600)
  }
}
